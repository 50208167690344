<template>
	<div v-if="data.singlePoint || markers">
		<div
			class="map__header map__header--top flex flex--between flex--top"
			v-if="filters"
		>
			<h3 class="main__title">{{ data.title }}</h3>
			<!--<FilterBox :data="filters"></FilterBox>-->
		</div>
		<div class="map__header map__header--bottom">
			<div class="map__tabs">
				<a
					class="map__tab map__tab-box"
					:class="mapType == 'map' ? 'map__tab-active' : ''"
					@click="changeMapType"
					>Карта</a
				>
				<a
					class="map__tab map__tab-list"
					:class="mapType == 'list' ? 'map__tab-active' : ''"
					@click="changeMapType"
					>Список</a
				>
			</div>
			<FilterBox :show="showFilter" @change:show="(show) => showFilter = show" :data="filters" @change="data => appliedFilters = data"></FilterBox>
		</div>
		<!--START LIST-->
		
		<div
			v-if="mapType == 'list'"
			class="map__box map__box-list"
			v-click-outside="closeMarkerInfo"
			style="display:block"
		>
				<section>
					<h3 class="main__title">Пункт выдачи ЕСК</h3>
					<p v-if="!filteredMarkersList || filteredMarkersList.length < 1">В вашем городе нет пунктов</p>
					<div
						v-for="(marker, index) in filteredMarkersList"
						:key="'list_wrapper_' + index"
						v-show="
							!filters.cardType ||
								(filters.cardType == 'isBankCardIssuer' &&
									marker.organization.isBankCardIssuer) ||
								(filters.cardType == 'isNonFinancialCardIssuer' &&
									marker.organization.isNonFinancialCardIssuer)
						"
					>
						<p class="map__divider" v-if="marker.hasOwnProperty('divider')">
							{{ marker.divider }}
						</p>
							<div class="map__list-row" :key="marker.id">
								<div class="map__list-col">
								<p :class="marker.hasOwnProperty('divider') ? 'first_name' : ''">
									{{ marker.organization.name }}
								</p>
									<span>{{ marker.address }}</span>
								</div>
								<div class="map__list-col">
									<p>Тип карты:</p>
									<span>
										<template v-if="marker.organization.isBankCardIssuer">
											Банковская
										</template>
										<template v-if="marker.organization.isNonFinancialCardIssuer">
											Нефинансовая
										</template>
									</span>
								</div>
								<div class="map__list-col">
									<p>Контакты:</p>
									<span><a style="color:#ed801b" :href="'tel:' + toNormalPhone(getPhone(marker.phone).phone).replace(/[\D]/g, '')">{{ toNormalPhone(getPhone(marker.phone).phone) }}</a>{{ getPhone(marker.phone).ext }}</span>
								</div>
							</div>
					</div>
				</section>
		</div>
		
		<!--END LIST-->
		<div
			v-if="mapType == 'map'"
			class="map__box"
			v-click-outside="closeMarkerInfo"
		>
			<transition name="map__info-slide">
				<perfect-scrollbar class="map__info" v-if="showMarkerInfo">
					<h4 class="map__heading">{{ markerInfoData.name }}</h4>
					<p class="map__text">
						<span class="icon icon--map-pin"></span>
						{{ markerInfoData.address }}
					</p>
					<a v-if="markerInfoData.phone" :href="'tel:' + markerInfoData.phone" class="map__text">
						<span class="icon icon--phone"></span>
						{{ toNormalPhone(getPhone(markerInfoData.phone).phone) }} {{ getPhone(markerInfoData.phone).ext }}
					</a>
					<a
						v-if="markerInfoData.mail"
						:href="'mailto' + markerInfoData.mail"
						class="map__text"
					>
						<span class="icon icon--mail"></span>
						{{ markerInfoData.mail }}
					</a>

					<template v-if="markerInfoData.operatingMode">
						<h4 class="map__heading">Часы работы</h4>
						<p class="map__text">
							<span class="icon icon--clock"></span>
							{{ markerInfoData.operatingMode }}
						</p>
					</template>

					<template v-if="markerInfoData.organization">
						<h4 class="map__heading">Тип карты</h4>
						<p class="map__text">
							<span class="icon icon--credit-card"></span>
							<span>
								<template v-if="markerInfoData.organization.isBankCardIssuer">Банковская</template>
								<template v-if="markerInfoData.organization.isNonFinancialCardIssuer">Нефинансовая</template>
							</span>
						</p>
					</template>

					<button class="map__button-close" @click="closeMarkerInfo">
						Закрыть
					</button>
				</perfect-scrollbar>
			</transition>
			<yandex-map
				class="map"
				v-if="settings"
				:settings="settings"
				:show-all-markers="setSiglePoint.allMarkers"
				:zoom="setSiglePoint.zoom"
				:coords="setSiglePoint.center || []"
				:cluster-options="clusterOptions"
				:controls="[
					'fullscreenControl',
					'typeSelector',
					'zoomControl',
					'geolocationControl',
				]"
			>
				<template v-for="group in markers">
					<ymap-marker
						v-for="marker in group.children"
						:options="group.markerOptions"
						:key="marker.id"
						:marker-id="marker.id"
						:coords="marker.geolocation"
						:icon="marker.icon || markerIcon"
						cluster-name="1"
						@click="getMarkerInfo(marker)"
					></ymap-marker>
				</template>
			</yandex-map>
		</div>
	</div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import FilterBox from '@/components/FilterBox'
import phone from '@/utils/phone'

const defaultAppliedFilters = {
	all: true,
	list: [],
	cardType: [],
}

export default {
	name: 'Map',

	components: {
		yandexMap,
		ymapMarker,
		FilterBox,
	},

	props: {
		data: {
			type: Object,
			required: true,
		},
		filters: {
			type: Object,
		},
	},

	data() {
		return {
			appliedFilters: defaultAppliedFilters,
			showFilter: false,

			settings: null,
			mapType: 'map',
			picked: this.$store.getters.PICKEDCITY,
			filteredMarkersList: [],
			zoom: 16,
			showMarkerInfo: false,
			markerInfoData: null,
			mapCenter: null,
			markerIcon: {
				layout: 'default#imageWithContent',
				imageHref:
					this.data.icon ||
					require('@/assets/images/pictures/icon_map-pin.svg'),
				imageSize: [65, 65],
				imageOffset: [-32.5, -65],
			},
			userIcon: {
				layout: 'default#imageWithContent',
				imageHref: require('@/assets/images/icons/icon_ymap.png'),
				imageSize: [22, 20],
				imageOffset: [-11, -10],
			},
			clusterOptions: {
				1: {
					maxZoom: 1, //Zoom при котором будут показываться все метки
					clusterize: false,
					clusterDisableClickZoom: false,
					clusterOpenBalloonOnClick: true,
					geoObjectHideIconOnBalloonOpen: false,
					preset: 'islands#darkOrangeClusterIcons',
				},
			},
		}
	},

	methods: {

		toNormalPhone(p){
			return p ? phone.toNormalPhone(p) : ''
		},
		getPhone(text) {
			if (!text) return { 'phone': '', 'ext': '' }

			let reg = /(?:[-+() ]*\d){10,13}/gm

			let phone = text.match(reg)
			// не распознали номер - оставим как есть @strokov_mm
			if (!phone || phone.length === 0) return { 'phone': text, 'ext': '' }

			let ext = text.split(phone[0])[1]
			return { 'phone': phone[0], 'ext': ext }
		},
		reformatPhone(value){
			return value ? phone.reformatPhone(value) : ''
		},
		doMapCenterScale() {
			this.zoom = 16
			this.mapCenter = [56.836357, 60.600322]

			this.settings.zoom = 16
			this.settings.mapCenter = this.mapCenter

			this.setSiglePoint.zoom = 16
			this.setSiglePoint.allMarkers = true
			this.setSiglePoint.center = this.$store.getters.PICKEDCITY.geolocation

			const data = {
				all: true,
				list: [4827],
				cardType: [],
			}
			this.$store.dispatch('SET_POINT_LIST_APPLIED_FILTERS', data)
		},
		citySorting(points = [], isFilter = false) {
			const pickedCity = this.$store.getters.PICKEDCITY
			if (!this.markers) return
			
			const markers = this.markers
				.filter(m => !m.markerOptions || m.markerOptions.visible)
				.reduce((acc, curr) => acc.concat(curr.children), [])

			if (points.length < 1 && !isFilter) {
				let list = markers.filter(marker => marker.city == pickedCity.id)
				let subList = []
				markers.forEach(item1 => {
					if (item1.divider !== undefined) delete item1.divider
					let has = false
					list.forEach(item2 => {
						if (item1.id == item2.id) has = true
					})
					if (!has) subList.push(item1)
					has = false
				})
				//В выбранном городе
				let fList = markers.filter(marker => marker.city == pickedCity.id)
				if (fList.length > 0) fList[0]['divider'] = 'В Вашем городе'
				//Сортировка
				if (subList.length > 0) subList[0]['divider'] = 'В других городах'
				//Объединяем 2 массива
				this.filteredMarkersList = fList.concat(subList)
			} else {
				let pickedList = markers.filter(
					marker => marker.city == pickedCity.id
				)
				let subList = []
				markers.forEach(item1 => {
					if (item1.divider !== undefined) delete item1.divider
					let has = false
					pickedList.forEach(item2 => {
						if (item1.id == item2.id) has = true
					})
					if (!has) subList.push(item1)
					has = false
				})
				//В выбранном городе
				let pl = []
				pickedList.forEach(f => {
					points.forEach(p => {
						if (f.id == p.id) pl.push(f)
					})
				})
				pickedList = pl
				if (pickedList.length > 0) pickedList[0]['divider'] = 'В Вашем городе'
				let sl = []
				subList.forEach(f => {
					points.forEach(p => {
						if (f.id == p.id) sl.push(f)
					})
				})
				//subList = this.sortingByKey(sl, 'city')
				if (subList.length > 0) subList[0]['divider'] = 'В других городах'
				//Объединяем 2 массива
				this.filteredMarkersList = pickedList.concat(subList)
			}
		},
		changeMapType(e) {
			let isActive = false
			e.target.classList.forEach(cl => {
				if (cl == 'map__tab-active') isActive = true
			})

			if (!isActive) {
				if (this.mapType == 'map') this.mapType = 'list'
				else this.mapType = 'map'
			}
		},
		getMarkerInfo(data) {
			this.markerInfoData = data
			this.showMarkerInfo = true
			this.mapCenter = data.geolocation
		},

		closeMarkerInfo() {
			this.showMarkerInfo = false
		},
		sortingByKey(json_object, key_to_sort_by) {
			function sortByKey(a, b) {
				var x = a[key_to_sort_by]
				var y = b[key_to_sort_by]
				return x < y ? -1 : x > y ? 1 : 0
			}

			return json_object.sort(sortByKey)
		},
	},
	computed: {
		pickedCity() {
			return this.$store.getters.PICKEDCITY
		},
		setSiglePoint() {
			let data = {
				zoom: 16,
				allMarkers: false,
				center: null,
			}
            let result = data
			if (this.data.singlePoint) {
				if (this.data.singlePoint.geolocation) {
					data.center = this.data.singlePoint.geolocation
				}
				result = data
			} else {
				if (this.markers.length === 1 && this.markers[0]) {
					data.center = this.markers[0].geolocation
					result = data
				} else {
					data.zoom = 16
					data.allMarkers = true
					data.center = this.$store.getters.PICKEDCITY.geolocation

					result = data
				}
			}
			result.zoom = 10
			return result
		},

		setCenter() {
			if (this.data.markers && this.markers.length === 1) {
				return this.markers[0].geolocation
			} else {
				return this.$store.getters.PICKEDCITY.geolocation
			}
		},

		userCoordinates() {
			return this.$store.getters.USER_COORDINATES
		},
		markers() {
			if (this.data.singlePoint)
				return { children: [this.data.singlePoint] }

			return this.data.groupedMarkers || []
		},
	},
	watch: {
		appliedFilters: {
			handler(filters) {
				if (this.data.singlePoint) return

				if (filters && (filters.cardType?.length || filters.list?.length)) {
					const isBankCardIssuer = filters.cardType?.some(ct => ct === 'isBankCardIssuer') || false
					const isNonFinancialCardIssuer = filters.cardType?.some(ct => ct === 'isNonFinancialCardIssuer') || false
					const anyCardIssuer = isBankCardIssuer === isNonFinancialCardIssuer
					const anyId = !filters.list?.length
					this.markers.forEach(group => {
						group.markerOptions = {
							visible: (anyCardIssuer ||
								(isBankCardIssuer && group?.organization?.isBankCardIssuer) ||
								(isNonFinancialCardIssuer && group?.organization?.isNonFinancialCardIssuer))
								&&
								(anyId || (group?.organization?.id && filters.list.includes(group.organization.id)))
						}
					})
				} else {
					this.markers.forEach(group => group.markerOptions = { visible: true })
				}
				// не костыль, а костылище @strokov_mm
				this.mapType = 'list'
				this.$nextTick(() => {
					this.mapType = 'map'
				})
				this.citySorting()
			},
			deep: true,
		},
	},
	created() {
		let self = this
		self.$nextTick(() => {
			setTimeout(function() {
				self.citySorting()
			}, 2000)
		})
		self.$root.$on('pickCity', function() {
			self.citySorting()
		})
		self.$root.$on('filteredCity', function(points) {
			self.citySorting(points, true)
		})
	},
	async mounted() {
		this.settings = {
			apiKey: process.env.VUE_APP_YANDEX_API_KEY,
			lang: 'ru_RU',
			coordorder: 'latlong',
			version: '2.1',
			center: this.$store.getters.PICKEDCITY?.geolocation,
			zoom: 16,
		}
	},
}
</script>
